import { template as template_0d25ccda13584fbd82b2e7dd5337d05a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_0d25ccda13584fbd82b2e7dd5337d05a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
