import { template as template_7579a1c9e7aa4398a4c0ad81b6209603 } from "@ember/template-compiler";
const FKLabel = template_7579a1c9e7aa4398a4c0ad81b6209603(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
