import { template as template_158b0e476d0c4e1e95d1db0cfcd2c3c1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_158b0e476d0c4e1e95d1db0cfcd2c3c1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
