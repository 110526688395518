import { template as template_d2abb1e78b1b4c99907916105f54773a } from "@ember/template-compiler";
const FKControlMenuContainer = template_d2abb1e78b1b4c99907916105f54773a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
