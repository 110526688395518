import { template as template_832e04cbdbd8415b9e4613151b345e40 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_832e04cbdbd8415b9e4613151b345e40(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
