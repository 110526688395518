import { template as template_74e23d4f9a5b485bbeaec1bb832234c1 } from "@ember/template-compiler";
const WelcomeHeader = template_74e23d4f9a5b485bbeaec1bb832234c1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
